/* globals */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 300px;
}

html {
  overflow-x: hidden;
}

.selectedColor {
  transition: ease-in-out 1s;
}

::-webkit-scrollbar{
  width: 13px;
  height: 13px;
  }
  ::-webkit-scrollbar-thumb{
  background: #FF8724;
  border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover{
  background: #FFAC63;
  }
  ::-webkit-scrollbar-track{
  background: #F0F0F0;
  border-radius: 0px;
  box-shadow: inset 3.7px 0px 9px 0px #D9D6D6;
  }


h1, h2 {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size : 8vw;
  font-weight: 900;
}

p { 
  font-size : 4vw;
}

button {
  height: 3em;
  font-weight: bold;
  border-radius: 3em;
  border: 2px solid rgb(206, 206, 206);
  background: transparent;
  color:  rgb(206, 206, 206);
  text-align: center;
  margin: 0.5em;
  padding: 0 1em;
  transition: 50ms ease-in-out;
}

button:focus {outline:0;}

button:hover {
  cursor: pointer;
  background:  rgba(225,9,175,1);
  border: 2px solid rgba(206, 206, 206, 0);
  transition: 150ms ease-in-out;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.05), 0px -5px 15px rgba(0,0,0,0.05);
}

button img {
  height: 1.1em;
  width: 1.1em;
  float: left;
  margin-right: 0.5em;
}

button:disabled {
  opacity: 0.2;
  pointer-events: none;
}

#selectColor1:hover, #selectColor2:hover {
  cursor: pointer;
}

.hidden {
  transition: 1s ease-in-out;
  visibility:hidden;
  opacity: 0;
  transition: all 1s ease-out; 
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
}

#nightmodeButton {
  height: 3em;
  border: none;
  width: 3em;
  background:  rgba(225,9,175,1);
  transition: 0.5s ease-in-out;
  border-radius: 3em;
  -webkit-box-shadow: 0px 10px 9px -4px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 10px 9px -4px rgba(0,0,0,0.2);
  box-shadow: 0px 10px 9px -4px rgba(0,0,0,0.2);
}

#nightmodeButton img {
  position: relative;
  width: 1em;
}

.paintable {
  cursor: pointer;
}

.app {
  width: 100%;
  margin: 0%;
}

.slick-dots li button:before {
  color: grey;
  font-size: 12px;
}

.slick-dots li button:focus, .slick-dots li button:hover {
  box-shadow: none;
}

.slick-prev {
  position: absolute;
  left: 12px;
  z-index: 1;
}

.slick-next {
  position: absolute;
  right: 12px;
  z-index: 1;
}

.slick-next:before, .slick-prev:before {
  color: gray;
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  background-color: transparent;
  border-radius: 25px;
}

.text-gradient {
  background-image: rgb(225,9,175);
  background-image: -moz-linear-gradient(270deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  background-image: -webkit-linear-gradient(170deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  background-image: linear-gradient(270deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  background-image: linear-gradient(270deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.energy-gradient {
  background: rgb(225,9,175);
  background: -moz-linear-gradient(270deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  background: -webkit-linear-gradient(170deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  background: linear-gradient(270deg, rgba(225,9,175,1) 0%, rgba(251,120,47,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e109af",endColorstr="#fb782f",GradientType=1);
}

.top-buttons {
  position: fixed;
  z-index: 120;
  top: 0px;
  right: 0px;
}

.marquee {
  width: 100vw;
  white-space: nowrap;
  overflow: hidden;
}
.marquee-content {
  text-align: unset;
  width: 800%;
  left: 10vw;
  display: flex;
  animation: marquee 60s linear infinite;
}

.tech-card {
  margin: 5vw 1vw;
  background-color: #fff;
  border-radius: 5px;
  padding: 2% 7%;
  -webkit-box-shadow: 0px 5px 27px -1px rgba(0,0,0,0.34); 
  box-shadow: 0px 5px 27px -1px rgba(0,0,0,0.34);
}

.tech-card img{
  height: 100%;
}

.title-panel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.sam-logo {
  margin: 10vw 10vw 
}

.top-splash {
  margin: -29vw 0em 10vw 8vw;
  height: 28em;
}

.hero-panel {
  text-align: center;
  padding: 10vw 0;
}

.hero-panel-text {
  margin: 10vw 10vw 10vw 10vw
}

.project-panel {
  text-align: center;
  padding: 3em 0em 0em 0em;
}

.project {
  position: relative; 
  z-index: 20;
  -webkit-box-shadow: 0px 5px 27px -1px rgba(0,0,0,0.14); 
  box-shadow: 0px 5px 27px -1px rgba(0,0,0,0.14);
  margin-top: 10vw;
}

.project-columns {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-text {
  padding: 10vw;
}

.footer-panel {
  overflow: hidden;
  margin-top: -65vw;
  z-index: 5;
}

.bottom-splash {
  width: 53em;
  position: relative;
  bottom: -1em;
  right: 28em;
}

.contact-form {
  text-align: center;
  padding: 10vw;
  position: relative;
  z-index: 10 ;
  /* margin: 10vw 10vw 0vw 10vw */
}


.portrait {
  position: relative;
  width: 80vw;
  margin: 0 0 0 0;
}

.bottom-link-icon {
  height: 3em;
  margin: 2.5em 0.5em;
  top: 4em;
  position: relative;
}

.slider-video {
  border: none;
}

.email-input {
  border-radius: 0.5em;
  height: 2em;
  padding: 2.5%;
  width: 95%;
  border: none;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.email-input:focus{
  outline: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.message-input {
  border-radius: 0.5em;
  height: 10em;
  padding: 2.5%;
  width: 95%;
  border: none;
  resize: none;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.message-input:focus{
  outline: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.ytp-show-cards-title {
  display: none;
}

/* paint colors */
.f1c0 {
  fill: #fff;
  stroke: #fff;
  stroke-width: 0.05%;
}

.f2c0 {
  fill: #acacac;
  stroke: #acacac;
  stroke-width: 0.05%;
}

.f3c0 {
  fill: #595959;
  stroke: #595959;
  stroke-width: 0.05%;
}

.f1c1 {
  fill: #fb782f;
  stroke: #fb782f;
  stroke-width: 0.05%;
}

.f2c1 {
  fill: #c75d22;
  stroke: #c75d22;
  stroke-width: 0.05%;
}

.f3c1 {
  fill: #6e3414;
  stroke: #6e3414;
  stroke-width: 0.05%;
}

.f1c2 {
  fill: #e109af;
  stroke: #e109af;
  stroke-width: 0.05%;
}

.f2c2 {
  fill: #9c0579;
  stroke: #9c0579;
  stroke-width: 0.05%;
}

.f3c2 {
  fill: #5c0347;
  stroke: #5c0347;
  stroke-width: 0.05%;
}

.flash {
  animation: none;
}

@keyframes pulsate {
  0%   { 
    fill: rgba(0, 0, 0, 1); 
    stroke: 2px solid rgba(0, 0, 0, 1);
  }
  50%  { 
    fill: rgba(0, 0, 0, 0);
    stroke: 2px solid rgba(0, 0, 0, 1); 
  }
  100% { 
    fill: rgba(0, 0, 0, 1); 
    stroke: 2px solid rgba(0, 0, 0, 1);
  }
}

/* MOBLIES DEVICES */
@media only screen and (min-width: 440px) {
  .top-splash {
    margin: -7vw 0em 10vw 10em;
    height: 23em;
  }
}

@media only screen and (min-width: 546px) {
  .top-splash {
    margin: -10vw 0em 10vw 6em;
    height: 36em;
  } 

  p {
    font-size: 2.6vw;
  }

  .footer-panel {
    overflow: hidden;
    /* position: relative; */
    margin-top: -31vw;
    z-index: 5;
  }
}

@media only screen and (min-width: 678px) {
  .top-splash {
    margin: -10vw 0em 10vw 11em;
    height: 43em;
  } 

  p {
    font-size: 2.5vw;
  }
}

@media only screen and (min-width: 870px) {
  .top-splash {
    margin: -3vw 0em 8vw 14em;
    height: 49em;
  }  
}

@media only screen and (min-width: 1040px) {
  .top-splash {
    margin: -3vw 0em 10vw 20em;
    height: 57em;
  }  
}

/* DESKTOPS */
@media only screen and (min-width: 1280px) {
  .top-buttons {
    position: absolute;
    z-index: 120;
    top: 0px;
    left: 0px;
  }

  #nightmodeButton {
    background-color: #fb782f;
    height: 2em;
    width: 2em;
    border-radius: 1em;
    padding: 0 0.5em;
  }

  #nightmodeButton img {
    width: 1em;
  }

  .slick-slide {
    /* slider not covering whole div for some reason */
    margin-bottom: -4px;
    margin-top: -4px;
  }

  .slick-dots li button:before {
    color: white;
    font-size: 15px;
  }

  button {
    height: 2.6em;
    border-radius: 2.6em;
    font-size: 1.5em;
    padding: 0em 1em;
  }

  #sendEmailButton {
    height: 2.6em;
    border-radius: 2.6em;
    font-size: 1.2em;
    padding: 0em 1em;
    margin-left: 0em;
  }
  
  h1,h2 {
    font-size: 4em;
    text-align: left;
    margin: 0px 1.3em;
  }

  p {
    font-size: 1.5em;
    text-align: left;
  }

  .flash {
    animation: pulsate 1.8s infinite;
    fill: rgba(0, 0, 0, 1);
    stroke: rgba(0, 0, 0, 1);
    stroke-width: 1px;
  }
  .sam-logo {
    margin: 12em 0em 0em 5em;
    height: 11em;
  }
  .top-splash {
    margin: -35em 0em 1em 34em;
    height: 46em;
  }

  .hero-panel {
    padding: 6em 0em 3em;
    margin: 0 0 3em 0;
  }

  .hero-panel-text {
    margin: 1em 3.6em;
    width: 29em;
  }

  .portrait {
    height: 23em;
    width: auto;
    left: 28em;
    top: -24em;
  }

  .tech-marquee {
    margin: -26em 0 -4em 0px;
  }

  .slick-dots {
    bottom: 1em;
  }

  .slick-dots li button:before {
    color: gray;
  }

  .project {
    margin-top: 3em;
  }

  .project-columns {
    display: flex;
    flex-direction: row;
  }

  .project-columns div {
    width: 50vw;
  }

  .project-text {
    padding: 2em 6em 0em 6.8em;
    font-size: 0.8em;
  }

  .footer-panel {
    margin-top: -18em;
    position: relative;
    bottom: 0px;
  }

  .bottom-splash {
    width: 60em;
    bottom: -2em;
    right: 18em;
  }

  .bottom-link-icon {
    height: 3em;
    padding: 0.5em;
    bottom: 5em;
    position: relative;
    opacity: 100%;
  }

  .bottom-link-icon:hover {
    opacity: 80%;
  }

  .project-title {
    text-align: left;
    margin: 0.2em 0em 0.5em 0em ;
    font-size: 4.5em;
  }

  .contact-form {
    text-align: left;
    padding: 5.4em;
    /* margin: 5em ; */
  }

  .contact-title {
    margin: 0 0 0 0px;
  }

  .email-input {
    padding: 0px 10px;
    width: 21em;
    height: 2em;
    font-size: 18px;
  }

  .message-input {
    padding: 10px 10px;
    width: 21em;
    height: 10em;
    font-size: 18px;
  }

  .contact-label {
    margin: 14px 0px 5px 0px;
  }

}

@media only screen and (min-width: 1440px) {
  .top-splash {
    margin: -35em 0em 1em 45em;
    height: 46em;
  }

  .portrait {
    height: 24em;
    width: auto;
    left: 29em;
    top: -25em;
  }
}

@media only screen and (min-width: 1536px) {
    .top-splash {
      margin: -36em 0em 1em 45em;
      height: 45em;
    }

    .portrait {
      height: 23em;
      width: auto;
      left: 31em;
      top: -24em;
  }
}

@media only screen and (min-width: 1920px) {

  .content {
    max-width: 1980px;
    margin:  auto;
    -webkit-box-shadow: 0px 0px 96px 42px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 96px 42px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 96px 42px rgba(0,0,0,0.1)
  }

  h1,h2 {
    font-size: 4em;
    text-align: left;
    margin: 0px 1.9em;
  }

  p {
    font-size: 2em;
    text-align: left;
  }


  .hero-panel {
    padding: 6em 0em 0em;
  }

  .hero-panel-text {
    margin: 1em 3.9em;
    width: 29em;
  }

  .sam-logo {
    margin: 16em 0em 0em 8em;
    height: 18em;
  }

  .top-splash {
    margin: -63em 0em 1em 53em;
    height: 77em;
  }

  .portrait {
    height: 23em;
    width: auto;
    left: 40em;
    top: -24em;
  }

  .project-columns div {
    width: 890px;
  }

  .project-text {
    padding: 2em 6em 0em 9.8em;
    font-size: 0.8em;
  }

  .tech-marquee {
    margin: -29em 0 -4em 0px;
  }

  .contact-form {
    text-align: left;
    padding: 3em 7.9em;
  }

  .contact-label {
    font-size: 24px;
  }

  .email-input {
    border-radius: 15px;
    padding: 0px 10px;
    width: 35em;
    height: 3em;
    font-size: 20px;
  }

  .message-input {
    border-radius: 15px;
    padding: 10px;
    width: 35em;
    height: 9em;
    font-size: 20px;
  }

  .bottom-splash {
    width: 100em;
    bottom: -2em;
    right: 40em;
  }
}